export const CloseIcon = () => (
  <svg
    className={'CloseIcon'}
    width="17px"
    height="18px"
    viewBox="0 0 17 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Desktop"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(-881.000000, -210.000000)" fill="#FFFFFF">
        <g
          id="Modal/Licao-Cadastrada-Sucesso"
          transform="translate(451.000000, 194.000000)"
        >
          <g
            id="Button/Fechar-Modal"
            transform="translate(422.000000, 8.000000)"
          >
            <polygon
              id="icone_mais"
              transform="translate(16.380952, 16.756603) rotate(-45.000000) translate(-16.380952, -16.756603) "
              points="15.5143128 5.77859328 15.3939454 15.7690906 5.4034481 15.889458 5.37936258 17.8885565 15.3698599 17.7681891 15.2494925 27.7586864 17.2475919 27.7346129 17.3679593 17.7441156 27.3584567 17.6237482 27.3825422 15.6246497 17.3920449 15.7450171 17.5124123 5.7545198"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
