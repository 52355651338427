import React, { ReactNode } from 'react';
import Head from 'next/head';
import '../theme/index.less';
import PWATooltip from './PWATooltip/PWATooltip';

type Props = {
  children?: ReactNode;
  title?: string;
};

// TODO type event
// interface BeforeInstallPromptEvent extends Event {
//   readonly platforms: Array<string>;

//   readonly userChoice: Promise<{
//     outcome: 'accepted' | 'dismissed',
//     platform: string
//   }>;

//   prompt(): Promise<void>;
// }

const Layout = ({ children, title = 'TIM - Folheto Digital' }: Props) => {
  // TODO native PWA-install-prompt -> test ->look for a better place ->refactor to a hook maybe
  React.useEffect(() => {
    const isUsingPWA = window.matchMedia('(display-mode: standalone)').matches;
    const alreadyClosed = localStorage.getItem('closed_pwa_tooltip');
    if (!isUsingPWA && !alreadyClosed)
      window.addEventListener('beforeinstallprompt', (evt: any) => {
        evt.preventDefault();
        evt.prompt().then((_userChoice: 'accepted' | 'dismissed') => {
          localStorage.setItem('closed_pwa_tooltip', Date.now().toString());
        });
      });
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
      </Head>

      {children}

      <PWATooltip />
    </>
  );
};

export default Layout;
