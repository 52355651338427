import styled from 'styled-components';
import cx from 'classnames';
import { useIosPwaCheck } from './useIosPwaCheck';
import { CloseIcon } from './CloseIcon';

const PWATooltipWrapper = styled.div`
  width: 100%;
  position: fixed;
  z-index: 100;
  bottom: 10px;
  left: 0;
  right: 0;
  justify-content: center;
`;

const Container = styled.div.attrs({
  className: 'ui-flex PWATooltip_Container'
})`
  width: 100%;
  padding: 20px;
  background-color: #f2f8ff;
  margin: 10px 10px 30px;
  border-radius: 10px;
  max-width: 370px;
  border-bottom: solid 3px #cdd4dc;

  &:after {
    content: ' ';
    width: 40px;
    height: 40px;
    position: absolute;
    left: calc(50% - 20px);
    bottom: -22px;
    background-color: #f2f8ff;
    transform: rotate(45deg);
    border-right: solid 3px #cdd4dc;
    border-bottom: solid 3px #cdd4dc;
  }
`;

const IconWrapper = styled.div.attrs({
  className: 'ui-flex PWATooltip_IconWrapper'
})`
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;

  .icon-app-tooltip {
    height: 80px;
    width: 80px;
    background-image: url(/static/favicon/apple-icon.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.12);
    border: solid 1px rgb(245, 233, 220);
  }
`;

const TextWrapper = styled.div.attrs({
  className: 'ui-flex PWATooltip_TextWrapper'
})`
  width: 100%;
  color: #444;
  line-height: 1.2;
  justify-content: center;
  text-align: center;
`;

const CloseButton = styled.div`
  height: 40px;
  width: 40px;
  padding: 10px;
  position: absolute;
  z-index: 2;
  right: 0;
  top: 0;

  svg {
    fill: #182857;
    g {
      fill: #182857;
    }
  }
`;

export const PWATooltip = (props: TPWATooltipProps) => {
  const { className } = props;
  const tooltip = useIosPwaCheck();

  if (!tooltip.showTooltip) return null;

  return (
    <PWATooltipWrapper
      className={cx(className, 'ui-flex PWATooltip PWATooltip_Wrapper')}
    >
      <Container>
        <CloseButton
          onClick={(ev) => {
            ev.preventDefault();
            tooltip.close();
          }}
        >
          <CloseIcon />
        </CloseButton>

        <IconWrapper>
          <div className="icon-app-tooltip" />
        </IconWrapper>

        <TextWrapper>
          Para instalar o App Folheto Digital, clique no ícone abaixo e depois
          em adicionar à tela inicial.
        </TextWrapper>
      </Container>
    </PWATooltipWrapper>
  );
};

export default PWATooltip;

export type TPWATooltipProps = {
  className?: string;
};
