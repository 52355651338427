import { ReactNode } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import cx from 'classnames';

import logo from './tim_logo.png';

const TopNavWrapper = styled.div`
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  z-index: 100;
`;

const Arrow = styled.a`
  width: 15px;
  height: 15px;
  display: block;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(45deg);
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  margin-right: -10px;
`;

const Logo = styled.div.attrs({ className: 'TopNav_Logo' })`
  background-image: url('${logo}');
  width: 30%;
  height: 23px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export const TopNav = (props: TTopNavProps) => {
  const { className, showBackButton } = props;
  const router = useRouter();

  const { _id, ddd, service } = router.query;

  const routerConfig = () => {
    if (_id && ddd && service) {
      return {
        pathname: '/folhetos',
        query: {
          ddd,
          service
        }
      };
    }

    if (ddd) {
      return {
        pathname: '/',
        query: {
          ddd
        }
      };
    }

    return '/';
  };

  return (
    <TopNavWrapper className={cx(className, 'ui-flex TopNav TopNav_Wrapper')}>
      {showBackButton ? (
        <Arrow
          color={'white'}
          role={'button'}
          href={'#'}
          onClick={(ev) => {
            ev.preventDefault();
            router.push(routerConfig());
          }}
        />
      ) : (
        <span />
      )}
      <Logo />
      <span />
    </TopNavWrapper>
  );
};

// export default TopNav;

export type TTopNavProps = {
  className?: string;
  children?: ReactNode;
  showBackButton?: boolean;
  queryString?: string;
};
