import { useState, useEffect } from 'react';

export function useIosPwaCheck() {
  const [showTooltip, setShowTooltip] = useState(false);

  function close() {
    setShowTooltip(false);
    localStorage.setItem('closed_pwa_tooltip', Date.now().toString());
  }

  const isIos = () => {
    try {
      const userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent);

      if (!ios) return false;
      return safari;
    } catch (e) {
      return false;
    }
  };

  // Detects if device is in standalone mode
  const isInStandaloneMode = () => {
    try {
      return (
        window.matchMedia('(display-mode: standalone)').matches ||
        // @ts-ignore
        window.navigator.standalone ||
        document.referrer.includes('android-app://')
      );
    } catch (e) {
      return null;
    }
  };

  useEffect(() => {
    const alreadyClosed = localStorage.getItem('closed_pwa_tooltip');

    if (!alreadyClosed && isIos() && !isInStandaloneMode()) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, []);

  return { showTooltip, close, isInStandaloneMode, isIos };
}
